
import { defineComponent, onBeforeMount } from 'vue';
import Page from '@/components/common/page.vue';
import { useTradeList } from '@/hooks/trade';
export default defineComponent({
  name: 'TradeList',
  components: {
    Page,
  },
  setup() {
    const { transactions, displayTransactions, getTransactionList } = useTradeList();
    onBeforeMount(() => {
      getTransactionList();
    });
    return {
      transactions,
      displayTransactions,
      getTransactionList,
    };
  },
});
