
import { defineComponent, onBeforeUnmount, onMounted, Ref, ref } from 'vue';
import { useThrottleFn } from '@vueuse/core';

let initialClientY = 0;

export default defineComponent({
  name: 'PageContainer',
  emits: ['nextPage'],
  setup: (prop, ctx) => {
    const scrollInstance: Ref<HTMLElement|null> = ref(null);
    let scrollHandle!: any;

    const nextPage = () => {
      ctx.emit('nextPage');
    };

    const setScrollTop = (number) => {
      const el = scrollInstance.value;
      if (!el) {
        return;
      }
      el.scrollTop = number;
    };
    const touchstart = (e) =>  {
      initialClientY = e.targetTouches[0].clientY;
    };
    const touchmove = (e) => {
      const el = scrollInstance.value;
      if (!el) {
        return;
      }
      const clientY = e.targetTouches[0].clientY - initialClientY;
      initialClientY = e.targetTouches[0].clientY;
      const { scrollTop, scrollHeight, clientHeight } = el;
      const isOnTop = clientY > 0 && scrollTop === 0;
      const isOnBottom = clientY < 0 && scrollTop + clientHeight + 1 >= scrollHeight;
      if (e.cancelable && (isOnTop || isOnBottom)) {
        e.preventDefault();
      }
      e.stopPropagation();
    };

    const onScroll = () => {
      if (!scrollInstance.value) {
        return;
      }
      let beforeScrollTop = scrollInstance.value.scrollTop;
      return (e) => {
        e.preventDefault();
        if (!scrollInstance.value) {
          return;
        }
        const afterScrollTop = scrollInstance.value.scrollTop;
        const derection = afterScrollTop - beforeScrollTop;
        const isDown = derection > 0;
        beforeScrollTop = afterScrollTop;
        if (checkIsBottom(20) && isDown) {
          nextPage();
        }
      };
    };
    // @ts-ignore
    const checkIsBottom = gap => scrollInstance.value.scrollTop
    // @ts-ignore
    + scrollInstance.value.clientHeight
    // @ts-ignore
    + gap > scrollInstance.value.scrollHeight
  ;

    onMounted(() => {
      if (!scrollInstance.value) {
        return;
      }
      // @ts-ignore
      scrollHandle = useThrottleFn(onScroll(), 500);
      scrollInstance.value.addEventListener('scroll', scrollHandle, false);
      scrollInstance.value.addEventListener('touchmove', touchmove, false);
      scrollInstance.value.addEventListener('touchstart', touchstart, false);
    });
    onBeforeUnmount(() => {
      if (!scrollInstance.value) {
        return;
      }
      scrollInstance.value.removeEventListener('scroll', scrollHandle);
      scrollInstance.value.removeEventListener('touchmove', touchmove);
      scrollInstance.value.removeEventListener('touchstart', touchstart);
    // this.scrollInstance.value.removeEventListener('scroll', this.scroll);
    });
    return {
      setScrollTop,
      scrollInstance,
    };
  },
});
