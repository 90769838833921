import { getCoupon, getMyCoupons } from '@/api/coupon';
import { useUrlSearchParams } from '@vueuse/core';
import { onBeforeMount, ref, Ref } from 'vue';
import { useDialog } from './dialog';
import { commonErrHandle } from './errHandle';
import { useLoading } from './loading';
import { to } from './to';

export const useCoupon = () => {
  const resetPage = () => {
    hasMore = true;
    isLoading = false;
    pageIndex.value = 1;
    couponList.value = [];
  };

  let isLoading = false;
  let hasMore = true;
  const pageIndex = ref(1);
  const pageSize = ref(10);
  // const unpaidTotal = ref(0);

  const couponList: Ref<any[]> = ref([]);
  const { showDialog } = useDialog();
  const { showLoading, hideLoading } = useLoading();

  const couponStatus: Ref<string> = ref('available');

  const switchCouponTab = async (e: any) => {
    const {
      dataset: { status = '' },
    } = e.target;
    couponStatus.value = status;
    resetPage();
    getCouponList();
  };

  const getCouponList = async () => {
    if (!hasMore) {
      return;
    }
    if (isLoading) return;
    isLoading = true;
    showLoading();
    const [err, res] = await to(
      getMyCoupons(pageIndex.value, pageSize.value, couponStatus.value)
    );
    hideLoading();
    if (commonErrHandle(err)) {
      showDialog({ content: err as string });
    }
    const { list } = res;
    couponList.value = [...couponList.value, ...list];
    hasMore = list.length >= pageSize.value;
    console.log(`hasMore-> ${hasMore}`);

    if (hasMore) {
      pageIndex.value = pageIndex.value + 1;
    }
    isLoading = false;
  };

  return {
    couponList,
    couponStatus,
    getCouponList,
    switchCouponTab,
    isLoading,
    resetPage,
  };
};

export const useCouponDetail = () => {
  const { showDialog } = useDialog();
  const { showLoading, hideLoading } = useLoading();
  showLoading();
  const couponDetail = async () => {
    const { id } = useUrlSearchParams('history');
    const [err, res] = await to(getCoupon(Number(id)));
    hideLoading();
    if (commonErrHandle(err)) {
      showDialog({ content: err as string });
    }
    return res;
  };
  const coupon = ref({
    coupon_name: '',
  });
  onBeforeMount(async () => {
    coupon.value = await couponDetail();
  });

  return {
    couponDetail,
    coupon,
  };
};
