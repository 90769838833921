
import { defineComponent } from 'vue';
import Icon from '@/components/common/icon.vue';
import { storeToRefs } from 'pinia';
import { useStore } from '@/stores/module/userInfo';
import { useOrder } from '@/hooks/order';
import { useMediaQuery } from '@vueuse/core';
import BindMobileModel from '@/components/bind-mobile.vue';
import { userHandleBindMobileModle, useUser } from '@/hooks/user';
import { useJumpPage } from '@/hooks/jumpPage';

export default defineComponent({
  name: 'OrderConfirm',
  components: {
    Icon,
    BindMobileModel,
  },
  setup: () => {
    const { isLogin } = useUser();
    const { navLogin } = useJumpPage();
    const isLargeScreen = useMediaQuery('(min-width: 750px)');
    const { isShowBindMobileModel, toggleModel } = userHandleBindMobileModle();
    const store = useStore();
    const { userInfo, hasMobile, hasUserInfo } = storeToRefs(store);
    const { submitOrder } = useOrder();
    return {
      isLogin,
      hasMobile,
      userInfo,
      hasUserInfo,
      navLogin,
      submitOrder,
      isLargeScreen,
      isShowBindMobileModel,
      toggleModel,
    };
  },
});
