
import { defineComponent, computed, ref } from 'vue';
import { useExpressRate } from '@/hooks/express';
import { useCountry } from '@/hooks/country';
import CountryPicker from '@/components/common/country-seletor.vue';
import Icon from '@/components/common/icon.vue';

export default defineComponent({
  name: 'ExpressRate',
  components: {
    CountryPicker,
    Icon,
  },
  setup: () => {
    const {
      expressList,
      reqForm,
      priceList,
      showExpressNote,
      gotoOrder,
      estimatePackage,
      toggleDimension,
    } = useExpressRate();
    const { getCountryInfoById } = useCountry();
    const displayCountryInfo = computed(() =>
      getCountryInfoById(reqForm.value.country)
    );
    const isShowPicker = ref(false);

    return {
      reqForm,
      priceList,
      isShowPicker,
      showExpressNote,
      toggleDimension,
      estimatePackage,
      onClosePicker: () => {
        isShowPicker.value = false;
      },
      showPicker: () => {
        isShowPicker.value = true;
      },
      displayCountryInfo,
      expressList,
      gotoOrder,
    };
  },
});
