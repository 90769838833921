
import { defineComponent, onBeforeMount, ref } from 'vue';
import Icon from '@/components/common/icon.vue';
import { usePackageInfo } from '@/hooks/packageInfo';
import { showDeclareTips } from '@/hooks/order';
import Gallery from '@/components/common/gallery.vue';

export default defineComponent({
  name: 'OrderDetail',
  components: {
    Icon,
    Gallery,
  },
  setup: () => {
    const isShowGallery = ref(false);
    const curImgIndex = ref(0);
    const {
      isShowMore,
      router,
      packageInfo,
      getRouter,
      showMoreTracking,
      getPackage,
    } = usePackageInfo();
    onBeforeMount(() => {
      getRouter();
      getPackage();
    });
    return {
      curImgIndex,
      isShowGallery,
      isShowMore,
      router,
      packageInfo,
      getRouter,
      showMoreTracking,
      getPackage,
      showDeclareTips,
      navToImg: (index: number) => {
        curImgIndex.value = index;
        isShowGallery.value = true;
      },
    };
  },
});
