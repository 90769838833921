
import { defineComponent, onActivated, ref } from 'vue';
import Icon from '@/components/common/icon.vue';
import { useJumpPage } from '@/hooks/jumpPage';
export default defineComponent({
  name: 'OrderProduct',
  components: {
    Icon,
  },
  setup: () => {
    const { navProductList } = useJumpPage();
    const products = ref([]);
    onActivated(() => {
      console.log(localStorage.getItem('products'));
      setTimeout(() => {
        products.value = JSON.parse(localStorage.getItem('products') || '[]') || [];
      }, 500);
    });
    return { products, navProductList };
  },
});
