
import { defineComponent } from 'vue';
import { loginByTikTok } from '@/hooks/login';

export default defineComponent({
  name: 'TikTokLogin',
  setup: () => {
    loginByTikTok();
    return {};
  },
});
