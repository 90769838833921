
import { defineComponent } from 'vue';
import { useOrder } from '@/hooks/order';

export default defineComponent({
  name: 'OrderPackageInfo',
  setup: () => {
    const { form } = useOrder();
    return {
      form,
    };
  },
});
