
import { defineComponent, onActivated, onBeforeMount, ref } from 'vue';
import { useStore } from '@/stores/module/userInfo';
import { storeToRefs } from 'pinia';
import Icon from '@/components/common/icon.vue';
import Statement from '@/components/statement.vue';
import { useMediaQuery } from '@vueuse/core';
import { useCharge } from '@/hooks/charge';
import { useJumpPage } from '@/hooks/jumpPage';
import router from '@/router';
import { useDialog } from '@/hooks/dialog';

export default defineComponent({
  name: 'Charge',
  components: {
    Icon,
    Statement,
  },
  setup() {
    const isLargeScreen = useMediaQuery('(min-width: 750px)');
    const { charge, chargeFrom } = useCharge();
    const { showDialog } = useDialog();
    const store = useStore();
    const { navTradeList, navLogin, navOrder } = useJumpPage();
    const { userInfo, isLogin } = storeToRefs(store);
    const chargeOptions = [
      {
        amount: 50,
        tip: '充值更放心',
        isHot: false,
        hotTip: '',
        chargeUrl: 'https://buy.stripe.com/5kAcN5aS95I6dwY4gj',
      },
      {
        amount: 80,
        tip: '',
        isHot: false,
        hotTip: '',
        chargeUrl: 'https://buy.stripe.com/8wMaEXe4lfiG3Wo7sw',
      },
      {
        amount: 100,
        tip: '更多人选择',
        isHot: true,
        hotTip: '最热门',
        chargeUrl: 'https://buy.stripe.com/bIY7sL6BT2vUboQfZ3',
      },
      {
        amount: 200,
        tip: '',
        isHot: false,
        hotTip: '',
        chargeUrl: 'https://buy.stripe.com/aEU9AT9O59Ym78A3ci',
      },
      {
        amount: 300,
        tip: '',
        isHot: false,
        hotTip: '',
        chargeUrl: 'https://buy.stripe.com/dR6dR9d0hb2qfF6aEL',
      },
      {
        amount: 500,
        tip: '充值更方便',
        isHot: true,
        hotTip: '最方便',
        chargeUrl: 'https://buy.stripe.com/9AQ3cvgcteeCgJabIQ',
      },
    ];
    const curSelectedIndex = ref(0);
    const selectOption = (index: number) => {
      curSelectedIndex.value = index;
    };
    const payWithConverge = () => {
      charge({
        amount: chargeOptions[curSelectedIndex.value].amount,
      });
    };

    const payWithCard = () => {
      if (!userInfo.value?.mobile) {
        showDialog({
          content: '请先绑定手机号!',
          btns: [
            {
              text: '前往绑定',
              fn: () =>
                navOrder({
                  expressId: '2',
                  country: 'US',
                }),
            },
            {
              text: '取消',
            },
          ],
        });
        return;
      }
      localStorage.setItem(
        'charge_amount',
        `${chargeOptions[curSelectedIndex.value].amount}`
      );
      router.push({
        name: 'ChargeCheckout',
      });
      // chargeFrom.value.amount = chargeOptions[curSelectedIndex.value].amount;
      // chargeFrom.value.visible = true;
    };

    const payWithStripeUrl = () => {
      if (!userInfo.value?.mobile) {
        showDialog({
          content: '请先绑定手机号!',
          btns: [
            {
              text: '前往绑定',
              fn: () =>
                navOrder({
                  expressId: '2',
                  country: 'US',
                }),
            },
            {
              text: '取消',
            },
          ],
        });
        return;
      }
      window.open(
        `${
          chargeOptions[curSelectedIndex.value].chargeUrl
        }?client_reference_id=${userInfo.value?.code || '-'}`,
        '_blank'
      );
    };

    onBeforeMount(() => {
      if (!isLogin.value) {
        navLogin();
        return;
      }
    });
    onActivated(() => {
      store.fetchUserInfo();
    });
    return {
      isLargeScreen,
      userInfo,
      chargeOptions,
      curSelectedIndex,
      selectOption,
      payWithConverge,
      navTradeList,
      payWithCard,
      payWithStripeUrl,
      chargeFrom,
    };
  },
});
