
import { defineComponent } from 'vue';
import { useBanner } from '@/hooks/banner';

export default defineComponent({
  name: 'HomeBanner',
  setup: () => {
    const { bannerList } = useBanner();
    return {
      bannerList,
      navToUrl: (str: string) => {
        location.href = str;
      } };
  },
});
