
import { defineComponent, ref } from 'vue';
import {
  getGoogleLoginCode,
  jumpToWxAuthPage,
  redirectToTikTok,
  useLogin,
} from '@/hooks/login';
import { isBytedanceWebview, isMobile, isTikTok, isWechat } from '@/libs/utils';
import { initWechatQrcode } from '@/api/login';
import { userHandleBindMobileModle } from '@/hooks/user';
import MobileLoginModel from '@/components/mobile-login.vue';

export default defineComponent({
  name: 'Login',
  components: {
    MobileLoginModel,
  },
  setup: () => {
    useLogin();
    const isWechatPhone = ref(isWechat());
    const isMobilePhone = ref(isMobile());
    const isShowQrcodeDialog = ref(false);
    const { isShowBindMobileModel, toggleModel } = userHandleBindMobileModle();
    return {
      isWechatPhone,
      isMobilePhone,
      isShowQrcodeDialog,
      jumpToWxAuthPage,
      getGoogleLoginCode,
      redirectToTikTok,
      isBytedanceWebview,
      isTikTok,
      isShowBindMobileModel,
      toggleModel,
      qrcodeLogin: () => {
        isShowQrcodeDialog.value = true;
        initWechatQrcode();
      },
    };
  },
});
