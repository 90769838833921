import { useStore } from '@/stores/module/userInfo';
import { ref } from 'vue';
import { postWechatPay, prePay, queryPayOrder } from '@/api/payment';
import {
  formatDate,
  isBytedanceWebview,
  isMobile,
  isWechat,
} from '@/libs/utils';

const paymentConfig = [
  {
    title: '格布钱包',
    note: '',
    id: 'balance',
    disabled: false,
    icon: 'https://res-cn.public.gblinker.com/gblinker/wechat_mini/images/789fc8489bf47b2aaa11675150919852.svg',
  },
  {
    title: '支付宝支付',
    note: '',
    id: 'alipay',
    disabled: isWechat() || isBytedanceWebview(),
    icon: 'https://res-cn.public.gblinker.com/gblinker/hub_panda/imgs/alipay_logo.png',
  },
  {
    title: '微信支付',
    id: 'wechat',
    note: '',
    disabled: isMobile(),
    icon: 'https://res-cn.public.gblinker.com/gblinker/wechat_mini/images/789fc8489bf47b2aaa11675150919854.svg',
  },
];

const coupon = {
  id: 0,
  coupon_amount: 0,
  coupon: {},
  coupon_type: '',
};
const wechatPay = async (
  packageIds: string[],
  userCouponIds?: string[]
): Promise<boolean> => {
  console.log(packageIds, userCouponIds);
  return await postWechatPay({
    packageIds,
    payType: isMobile() && isWechat() ? 'mp_wechat' : 'native_wechat',
    userCouponIds,
  });
};

const aliPay = async (
  packageIds: string[],
  userCouponIds?: string[]
): Promise<boolean> => {
  const payType = isMobile() ? 'm_alipay' : 'pc_alipay';
  console.log(`payType: ${payType}`);
  return await postWechatPay({ packageIds, payType, userCouponIds });
};

const balancePay = async (
  packageIds: string[],
  userCouponIds?: string[]
): Promise<boolean> => {
  console.log(packageIds);
  return await postWechatPay({ packageIds, payType: 'balance', userCouponIds });
};

const paySwitch = (type) => {
  let fn;
  switch (type) {
    case 'wechat':
      fn = wechatPay;
      break;
    case 'balance':
      fn = balancePay;
      break;
    case 'alipay':
      fn = aliPay;
      break;
    default:
      alert('您未选择支付方式');
      break;
  }
  return fn;
};

const queryOrder = async (outTradeNo: string) =>
  await queryPayOrder(outTradeNo);

const paymentDialogForm = ref({
  visible: false,
  title: '',
  qrcode: '',
});

export const usePayment = () => {
  const store = useStore();
  const payment = ref(paymentConfig);
  const selectPayment = ref('balance');
  const hasCoupon = ref(false);
  const selectCoupon = ref(coupon);
  const coupons = [] as any;
  selectCoupon.value = {
    id: 0,
    coupon_amount: 0,
    coupon: {},
    coupon_type: '',
  };
  paymentDialogForm.value = {
    visible: false,
    title: '',
    qrcode: '',
  };
  const initPaymentInfo = async (packageIds: string[]) => {
    await store.fetchUserInfo();
    const data = await prePay(packageIds);
    const { user_balance: userBalance, user_coupons: userCoupons } = data;
    const formatBalance = userBalance.toFixed(2).toLocaleString('zh-CN');
    // 解析优惠券
    for (const item of userCoupons.available_coupons) {
      item.expiration_time = formatDate('yyyy.mm.dd', item.expiration_time);
      item.availabled = true;
      item.checked = false;
      item.coupon = JSON.stringify(item);
      coupons.push(item);
    }
    hasCoupon.value = userCoupons.available_coupons.length > 0;
    for (const item of userCoupons.disabled_coupons) {
      item.expiration_time = formatDate('yyyy.mm.dd', item.expiration_time);
      item.availabled = false;
      item.checked = false;
      item.coupon = JSON.stringify(item);
      coupons.push(item);
    }
    payment.value = paymentConfig.map((item) => {
      let note = '';
      if (item.id === 'balance') {
        note = `（剩余¥${formatBalance}）`;
      }
      return { ...item, note };
    });
  };

  const changePayment = (item) => {
    if (item.disabled) {
      return;
    }
    selectPayment.value = item.id;
  };

  return {
    payment,
    selectPayment,
    changePayment,
    paySwitch,
    hasCoupon,
    selectCoupon,
    initPaymentInfo,
    coupons,
    queryOrder,
    paymentDialogForm,
  };
};
