import { request } from '@/libs/request';

/** 获取快递路线列表 */
export const getExpressList = async (country: string) => {
  const data = await request({
    url: '/v1/express/getexpresslist',
    method: 'GET',
    params: {
      country,
    },
  });
  return data.map(item => ({
    ...item,
    id: `${item.id}`,
  }));
};

export const getExpressQuation = async (params) => {
  const data = await request({
    url: '/v1/express/quotation/many',
    method: 'GET',
    params: {
      country: params.country,
      size_length: params.sizeLength,
      size_width: params.sizeWidth,
      size_height: params.sizeHeight,
      weight: Number(params.weight) * 1000,
    },
  });
  if (!data) throw '测算价格超出限制，请联系公众号客服“格布小包”';
  const priceMap: any = {};
  for (const item of data) {
    priceMap[item.express_id] = item;
  }
  return priceMap;
};

