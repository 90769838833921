
import { defineComponent,ref } from 'vue';
import { useStore } from '@/stores/module/userInfo';
import { storeToRefs } from 'pinia';
import HomeBanner from '@/components/home-banner.vue';
import HomeAddress from '@/components/home-address.vue';
import HomeTransition from '@/components/home-transition.vue';
import HomeMore from '@/components/home-more.vue';
import { isMobile,isWechat } from '@/libs/utils';

export default defineComponent({
  name: 'Home',
  components: {
    HomeBanner,
    HomeAddress,
    HomeTransition,
    HomeMore,
  },
  setup: () => {
    const store = useStore();
    const { userInfo } = storeToRefs(store);
    const showQrcodeDialog = ref(false)
    const handleCustomerServices = ()=>{
     if(isWechat()||isMobile()){
          location.href = 'https://work.weixin.qq.com/kfid/kfc955955a848e77bbf'
      }else{
          showQrcodeDialog.value = !showQrcodeDialog.value
      }
    }
    return {
      userInfo,
      showQrcodeDialog,
      handleCustomerServices
    };
  },
});
