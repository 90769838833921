import { request } from '@/libs/request';
import { formatDate } from '@/libs/utils';

export const getMyCoupons = async (
  pageIndex: number,
  pageSize: number,
  couponStatus: string
) => {
  const { list } = await request({
    url: '/v1/coupon/user/my',
    method: 'GET',
    params: {
      page_index: pageIndex,
      page_size: pageSize,
      list_filter: couponStatus,
    },
  });
  // 解析优惠券
  const coupons = [] as any;
  for (const item of list) {
    // 低于三天的提示快过期了
    item.isExpired = !!item.is_expiration;
    item.expiration_time = formatDate('yyyy.mm.dd', item.expiration_time);
    item.effective_time = formatDate('yyyy.mm.dd', item.effective_time);
    item.availabled = true;
    item.coupon = JSON.stringify(item);
    coupons.push(item);
  }
  return { list: coupons };
};

export const getCoupon = async (couponId: number) => {
  const coupon = await request({
    url: `/v1/coupon/user/my/${couponId}`,
    method: 'GET',
  });
  coupon.isExpired = !!coupon.is_expiration;
  coupon.expiration_time = formatDate('yyyy.mm.dd', coupon.expiration_time);
  coupon.effective_time = formatDate('yyyy.mm.dd', coupon.effective_time);
  coupon.availabled = true;
  coupon.coupon = JSON.stringify(coupon);
  return coupon;
};
