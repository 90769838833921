import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f7e12b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "g-card warehouse-address" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "desc" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "body" }
const _hoisted_7 = { class: "item" }
const _hoisted_8 = { class: "text" }
const _hoisted_9 = { class: "item" }
const _hoisted_10 = { class: "text" }
const _hoisted_11 = { class: "item" }
const _hoisted_12 = { class: "text" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = {
  key: 1,
  class: "empty-userinfo"
}
const _hoisted_15 = {
  key: 2,
  class: "empty-userinfo"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_BindMobileModel = _resolveComponent("BindMobileModel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('中转仓地址')), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('将货物寄到以下地址，完成转运')), 1)
    ]),
    (_ctx.hasMobile)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.warehouse, (item, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_Icon, {
                    class: "icon",
                    icon: "icon_user"
                  }),
                  _createElementVNode("text", _hoisted_8, _toDisplayString(item.contactName), 1)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_Icon, {
                    class: "icon",
                    icon: "icon_phone"
                  }),
                  _createElementVNode("text", _hoisted_10, _toDisplayString(item.phone), 1)
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_Icon, {
                    class: "icon",
                    icon: "icon_address"
                  }),
                  _createElementVNode("text", _hoisted_12, _toDisplayString(item.contactAddress), 1)
                ])
              ]),
              _createElementVNode("div", {
                class: "footer",
                onClick: ($event: any) => (_ctx.copyAddress(item))
              }, _toDisplayString(_ctx.$t('点击复制')), 9, _hoisted_13)
            ]))
          }), 128))
        ]))
      : (!_ctx.isLogin)
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            _createElementVNode("button", {
              class: "g-button g-button-primary link",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.navLogin && _ctx.navLogin(...args)))
            }, _toDisplayString(_ctx.$t('登录查看中转仓地址')), 1)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _createElementVNode("button", {
              class: "g-button g-button-primary link",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleModel && _ctx.toggleModel(...args)))
            }, _toDisplayString(_ctx.$t('绑定手机号，查看中转仓地址')), 1)
          ])),
    _createVNode(_component_BindMobileModel, {
      "is-show-model": _ctx.isShowBindMobileModel,
      onClose: _ctx.toggleModel
    }, null, 8, ["is-show-model", "onClose"])
  ]))
}