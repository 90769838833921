
import { defineComponent, computed } from 'vue';
import Icon from '@/components/common/icon.vue';
import { useOrder } from '@/hooks/order';
import { useJumpPage } from '@/hooks/jumpPage';
import { useCountry } from '@/hooks/country';
import { getAddressDetail } from '@/hooks/address';
import { AddressListMode } from '@/types/address';
// import { useStore } from '@/stores/module/userInfo';
import { useUser } from '@/hooks/user';
export default defineComponent({
  name: 'RecipientInfo',
  components: {
    Icon,
  },
  setup: () => {
    const { form } = useOrder();
    const { isLogin } = useUser();
    const { navAddressList, navLogin } = useJumpPage();
    const { getCountryInfoById } = useCountry();
    const countryInfo = computed(() => getCountryInfoById(form.value.country));
    return {
      form,
      isLogin,
      navLogin,
      getAddressDetail,
      toSelectAddress: () => {
        navAddressList({
          mode: AddressListMode.Select,
        });
      },
      countryInfo,
    };
  },
});
