
import { computed, defineComponent, ref } from 'vue';
import CountryPicker from '@/components/common/country-seletor.vue';
import { useOrder } from '@/hooks/order';
import { useCountry } from '@/hooks/country';
export default defineComponent({
  name: 'OrderCountry',
  components: {
    CountryPicker,
  },
  setup: () => {
    const { form } = useOrder();
    const { getCountryInfoById } = useCountry();
    const isShowPicker = ref(false);
    const displayCountryInfo = computed(() =>
      getCountryInfoById(form.value.country)
    );

    return {
      isShowPicker,
      displayCountryInfo,
      form,
      onClosePicker: () => {
        isShowPicker.value = false;
      },
      showPicker: () => {
        isShowPicker.value = true;
      },
    };
  },
});
