import { request } from '@/libs/request';
import { formatDate } from '@/libs/utils';

export const getTeamMember = async () => {
  const data = await request({
    url: '/v1/user/distributor',
    method: 'GET',
  });
  const items: any[] = [];
  let cursor = 1;
  for (const item of data) {
    items.push(Object.assign({}, item, {
      avatar: item.info ? item.info.avatarUrl : 'https://res-cn.public.gblinker.com/gblinker/wechat_mini/images/c77011712c7c214c136f0d0ea7d23c84.png',
      created: formatDate('yyyy-mm-dd h:i:s', item.created),
      commissionSum: item.commissionSum ? item.commissionSum.toFixed(2) : '0.00',
      name: item.name ? item.name : `格布0${cursor}`,
    }));
    if (!item.name) {
      cursor = cursor + 1;
    }
  }
  return items;
};
