
import { defineComponent } from 'vue';
import Icon from '@/components/common/icon.vue';
import { useOrder } from '@/hooks/order';

export default defineComponent({
  name: 'OrderRemark',
  components: {
    Icon,
  },
  setup: () => {
    const { form, QUICK_REMARK } = useOrder();
    return {
      form,
      QUICK_REMARK,
    };
  },
});
