import { request } from '@/libs/request';

/**
 * 创建充值订单
 * 获取 支付平台 token 和 订单号
 * @param param
 * @returns
 */
export const createChargeOrder = async (param: any): Promise<any> => {
  const data = await request({
    url: '/v1/recharge',
    method: 'post',
    data: {
      // amount: param.amount,
      // card_number: param.card_number,
      // expiration_date: param.expiration_date,
      // cvv: param.cvv,
      ...param,
      pay_by: param.pay_by || 'convergepay',
    },
  });
  return {
    auth_token: data.auth_token,
    verification: data.verification,
    order_num: data.order_num,
  };
};
/** 充值完成 回调 */
export const chargeCallback = async (data: any): Promise<any> => await request({
  url: '/v1/recharge/callback',
  method: 'post',
  data,
});
