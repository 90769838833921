import { request } from '@/libs/request';
import { formatDate } from '@/libs/utils';
export const getPkgRoute = async (packageId: string) => {
  const res = await request({
    url: `/v1/package/${packageId}/tracking`,
    method: 'GET',
  });
  return res;
};

export const getPkgInfo = async (packageId: string) => {
  const res = await request({
    url: `/v1/package/sub/${packageId}`,
    method: 'GET',
  });
  return {
    ...res,
    created: formatDate('yyyy-mm-dd h:i:s', res.created),
    weight: res.weight ? `${(res.weight / 1000).toFixed(3)}KG` : '-',
    rate_weight: res.rate_weight ? `${(res.rate_weight / 1000).toFixed(3)}KG` : '-',
  };
};


