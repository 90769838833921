
import { defineComponent, onMounted, ref } from 'vue';
import { useJumpPage } from '@/hooks/jumpPage';
import QRCode from 'qrcode';
import { setDistributionParam } from '@/hooks/login';
import { useToast } from '@/hooks/toast';
import { useClipboard } from '@vueuse/core';

const backGroundUrl =
  'https://res-public-hk.public.gblinker.com/gblinker/qrcode/qrcodebackground.jpg';
export default defineComponent({
  setup() {
    const { navOrder } = useJumpPage();
    const shareLink = ref(location.origin);
    const { showToast } = useToast();
    onMounted(async () => {
      const img = new Image();
      img.src = backGroundUrl;
      const cv = document.getElementById('canvas') as HTMLCanvasElement;
      cv.width = 750;
      cv.height = 1344;
      let extra = localStorage.getItem('extra_item') || undefined;
      if (!extra) {
        await setDistributionParam();
        extra = localStorage.getItem('extra_item') || undefined;
      }
      shareLink.value = `${location.origin}?extra=${extra}`;
      const url = await QRCode.toDataURL(
        `${location.origin}?extra=${extra}`
      ).then((url) => url);
      const qrcode = new Image();
      qrcode.src = url;
      const ctx = cv.getContext('2d');
      img.onload = () => {
        ctx?.drawImage(img, 0, 0, cv.width, cv.height);
        ctx?.drawImage(qrcode, 232, 190, 290, 290);
      };
    });

    return {
      shareLink,
      navOrder,
      copyLink() {
        const { copy, isSupported } = useClipboard({ source: shareLink });
        if (!isSupported) {
          return alert('浏览器不支持复制功能');
        }
        copy();
        showToast('复制成功');
      },
    };
  },
});
