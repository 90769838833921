
import { defineComponent, watch } from 'vue';
import PackageInfoCountry from './package-info-country.vue';
import PackageInfoTracking from './package-info-tracking.vue';
import PackageInfoValue from './package-info-value.vue';
import PackageInfoCargo from './package-info-cargo.vue';
import OrderProduct from './package-info-product.vue';
import Icon from '@/components/common/icon.vue';
import { useOrder } from '@/hooks/order';
import { useDebounceFn } from '@vueuse/core';
import { useCountry } from '@/hooks/country';
export default defineComponent({
  name: 'OrderPackageInfo',
  components: {
    PackageInfoCountry,
    PackageInfoTracking,
    PackageInfoValue,
    PackageInfoCargo,
    OrderProduct,
    Icon,
  },
  setup: () => {
    const { form, showProduct } = useOrder();
    const { getCountryInfoById } = useCountry();
    const onCountryChange = useDebounceFn((val, old) => {
      if (val !== old) {
        const countryInfo = getCountryInfoById(val) as any;
        const { orderPlugins = [] } = countryInfo || {};
        showProduct.value = !!orderPlugins.find(({ form_name }) => form_name === 'products');
      }
    });
    watch(() => form.value.country, onCountryChange, {
      immediate: true,
      deep: true,
    });
    return { form, showProduct };
  },
});
