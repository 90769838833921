
import { defineComponent } from 'vue';
import { useJumpPage } from '@/hooks/jumpPage';
import { useCouponDetail } from '@/hooks/coupon';
export default defineComponent({
  components: {},
  setup() {
    const { coupon } = useCouponDetail();
    const { navOrder } = useJumpPage();

    return {
      coupon,
      gotoOrdering: () => {
        navOrder(undefined);
      },
    };
  },
});
