
import { computed, defineComponent } from 'vue';
import Icon from '@/components/common/icon.vue';
import { useOrder, showDeclareTips } from '@/hooks/order';
import { yuan2Fen } from '@/libs/utils';
export default defineComponent({
  name: 'OrderDeclaredValue',
  components: {
    Icon,
  },
  setup: () => {
    const { form } = useOrder();
    const isShowTip = computed(
      () => Number(yuan2Fen(form.value.declareAmount)) > 10000
    );
    return { form, isShowTip, showDeclareTips };
  },
});
