import { request } from '@/libs/request';

export const getTradeList = async ({ size, pageIndex }) => {
  const data = await request({
    url: '/v1/journal',
    method: 'GET',
    params: {
      page_size: size,
      page_index: pageIndex,
    },
  });
  return { list: data, listLength: data.length };
};
