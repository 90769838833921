
import { defineComponent } from 'vue';
import { useExpress } from '@/hooks/express';
import { useOrder } from '@/hooks/order';
export default defineComponent({
  name: 'OrderCargo',
  setup: () => {
    const { expressList } = useExpress();
    const { form, setForm } = useOrder();
    return {
      expressList,
      form,
      setExpressId: (express) => {
        setForm('expressId', express.id);
      },
    };
  },
});
