import { getTradeList } from '@/api/trade';
import { formatDate } from '@/libs/utils';
import { ref, Ref } from 'vue';
import { useDialog } from './dialog';
import { commonErrHandle } from './errHandle';
import { useLoading } from './loading';
import { to } from './to';
const tradingType: any = {
  PAY: '支付',
  CHARGE: '充值',
  COMMISSION: '佣金',
  REFUND: '退款',
  WITHDRAW: '提现',
};
export const useTradeList = () => {
  const { showLoading, hideLoading } = useLoading();
  const { showDialog } = useDialog();
  const pageIndex = ref(1);
  let isLoading = false;
  let hasMore = true;
  const size = 15;
  const displayTransactions: Ref<{
    [key: string]: any[]
  }> = ref({});
  const transactions: Ref<any[]> = ref([]);

  const getTransactionList = async () => {
    if (!hasMore) {
    //   wx.showToast({
    //     title: '已经到底了',
    //     icon: 'error',
    //   });
      return;
    }
    if (isLoading) return;
    isLoading = true;
    showLoading();
    const [err, data] = await to(getTradeList({
      size,
      pageIndex: pageIndex.value,
    }));
    hideLoading();
    if (commonErrHandle(err)) {
      return showDialog({ content: '请求失败，请重试' });
    }
    transactions.value = [...transactions.value, ...data.list];

    transactions.value.forEach((item) => {
      const month = formatDate('yyyy年mm月', item.created);
      if (!displayTransactions.value[month]) {
        displayTransactions.value[month] = [];
      }
      displayTransactions.value[month].push(Object.assign(
        {},
        {
          trading_type_label: tradingType[item.trading_type],
        },
        item,
        {
          created: formatDate('yyyy-mm-dd h:i:s', item.created),
        }
      ));
    });
    isLoading = false;
    hasMore = data.listLength >= size;
    pageIndex.value = pageIndex.value + 1;
  };

  return {
    transactions,
    displayTransactions,
    getTransactionList,
  };
};
