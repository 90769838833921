
import { defineComponent, onBeforeMount } from 'vue';
import { useCoupon } from '@/hooks/coupon';
import { useJumpPage } from '@/hooks/jumpPage';
import PageContainer from '@/components/common/page.vue';
import { useMediaQuery } from '@vueuse/core';
import Icon from '@/components/common/icon.vue';
export default defineComponent({
  components: {
    PageContainer,
    Icon,
  },
  setup() {
    const isLargeScreen = useMediaQuery('(min-width: 750px)');
    const { couponList, getCouponList, switchCouponTab, couponStatus } =
      useCoupon();
    const { navOrder, navToCouponDetail } = useJumpPage();
    onBeforeMount(() => {
      getCouponList();
    });
    return {
      isLargeScreen,
      couponList,
      switchCouponTab,
      couponStatus,
      gotoOrdering: () => {
        navOrder(undefined);
      },
      gotoCouponDetail: (v: any) => {
        navToCouponDetail(v.id);
      },
      getPageData: () => {
        console.log(333);
        getCouponList();
      },
    };
  },
});
