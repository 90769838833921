
import { defineComponent, onActivated } from 'vue';
import PackageInfo from '@/components/order/package-info.vue';
import RecipientInfo from '@/components/order/recipient-info.vue';
import OrderProtocol from '@/components/order/protocol.vue';
import OrderRemark from '@/components/order/remark.vue';
import OrderConfirm from '@/components/order/confirm.vue';
import { useOrder } from '@/hooks/order';
import { useUrlSearchParams } from '@vueuse/core';
let isFromSelectedPage = false;

export default defineComponent({
  name: 'Order',
  components: {
    PackageInfo,
    RecipientInfo,
    OrderProtocol,
    OrderRemark,
    OrderConfirm,
  },
  beforeRouteEnter: (to, from) => {
    const whiteList = ['AddressList', 'BindMobile', 'ProductList'];
    isFromSelectedPage = whiteList.includes(from.name as string);
    if (!isFromSelectedPage) {
      localStorage.removeItem('products');
    }
  },
  setup: () => {
    const { form, resetForm } = useOrder();

    onActivated(async () => {
      const data = useUrlSearchParams('history');

      if (data.expressId) {
        form.value.expressId = data.expressId as string;
      }
      if (data.country) {
        form.value.country = data.country as string;
      }
      if (!isFromSelectedPage) {
        localStorage.removeItem('products');
        await resetForm();
      }
    });
    return {
      form,
    };
  },
});
