import { ProductForm } from '@/types/products';
import { defineStore } from 'pinia';

type State = {
  products: ProductForm[]
};
export const useProductStore = defineStore('products', {
  state: (): State => ({
    products: [],
  }),
  actions: {
    reset() {
      this.products = [];
    },
  },
});
