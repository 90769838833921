
import { defineComponent, onBeforeMount } from 'vue';
export default defineComponent({
  name: 'Tips',
  setup: () => {
    onBeforeMount(() => {
      (document.getElementsByClassName('header')[0] as any).style.display =
        'none';
    });
    return {};
  },
});
