
import { defineComponent, onBeforeMount } from 'vue';
import Icon from '@/components/common/icon.vue';
import { useWareHouse } from '@/hooks/address';
export default defineComponent({
  name: 'WareHouseList',
  components: {
    Icon,
  },
  setup: () => {
    const { copyAddress, warehouse, fetchWareHouseList } = useWareHouse();
    onBeforeMount(() => {
      fetchWareHouseList();
    });
    return {
      copyAddress,
      warehouse,
    };
  },
});
