import { ref } from 'vue';
let timeouter;
export const useCountDown = () => {
  const count = ref(60);
  const isCounting = ref(false);
  const reset = () => {
    clearTimeout(timeouter);
    isCounting.value = false;
  };
  const start = () => {
    timeouter = setTimeout(() => {
      if (count.value === 0) {
        return reset();
      }
      count.value = count.value - 1;
      isCounting.value = true;
      start();
    }, 1000);
  };
  return {
    isCounting,
    count,
    reset,
    start,
  };
};
