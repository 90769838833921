
import { defineComponent } from 'vue';
import { useCharge } from '@/hooks/charge';
import { useJumpPage } from '@/hooks/jumpPage';
export default defineComponent({
  name: 'ExpressRate',
  components: {},
  setup: () => {
    const { chargeFrom, chargeByCard, resetForm } = useCharge();
    const { navMine } = useJumpPage();
    resetForm(chargeFrom);
    const chargeAmount = localStorage.getItem('charge_amount');
    if (!chargeAmount) {
      navMine();
      return;
    }
    chargeFrom.value.amount = Number(chargeAmount);
    const toggleBillInfo = () => {
      chargeFrom.value.visible = !chargeFrom.value.visible;
    };
    return {
      toggleBillInfo,
      chargeByCard,
      chargeFrom,
    };
  },
});
