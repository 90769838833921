
import { defineComponent } from 'vue';
import Statement from '@/components/statement.vue';
import { useJumpPage } from '@/hooks/jumpPage';
export default defineComponent({
  name: 'ChargeFail',
  components: {
    Statement,
  },
  setup: () => {
    const { replaceCharge } = useJumpPage();
    return {
      replaceCharge,
    };
  },
});
