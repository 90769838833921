import { request } from '@/libs/request';

export const postWechatPay = async ({
  packageIds,
  payType,
  userCouponIds,
}: {
  packageIds: string[];
  payType: string;
  userCouponIds?: string[];
}) => {
  const res = await request({
    url: '/v1/package/pay',
    method: 'POST',
    data: {
      package_ids: packageIds,
      pay_type: payType,
      user_coupon_ids: userCouponIds,
    },
  });
  return res;
};

export const prePay = async (packageIds: string[]): Promise<any> => {
  const res = await request({
    url: '/v1/package/begin/payment',
    method: 'POST',
    data: {
      package_ids: packageIds,
    },
  });
  return res;
};

export const queryPayOrder = async (outTradeNo: string): Promise<any> => {
  const res = await request({
    url: `/v1/payment/${outTradeNo}`,
    method: 'GET',
  });
  return res;
};

export const beforePay = async (payOrderId: any): Promise<any> => {
  const res = await request({
    url: `/v1/payment/pay-order/${payOrderId}`,
    method: 'GET',
    data: {},
  });
  return res;
};

export const payOrder = async (payType: string, payOrderId: number) => {
  const res = await request({
    url: '/v1/payment/pay-order/pay',
    method: 'POST',
    data: { pay_type: payType, pay_order_id: payOrderId },
  });
  return res;
};
