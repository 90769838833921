
import { defineComponent, onBeforeMount, computed } from 'vue';
import Icon from '@/components/common/icon.vue';
import Payment from '@/components/common/payment.vue';
import { useOrderComplete, showDeclareTips, useOrderPay } from '@/hooks/order';
import { useCountry } from '@/hooks/country';
import { useJumpPage } from '@/hooks/jumpPage';
import { OrderCompleteEnum } from '@/types/router';
import { USER_CLICK_PACKAGE_CACHE } from '@/config/index';
export default defineComponent({
  name: 'OrderDetail',
  components: {
    Icon,
    Payment,
  },
  setup: () => {
    const { navOrderCompleteList, navSubPkg } = useJumpPage();
    const { getCountryInfoById } = useCountry();
    const { packageInfo, initPckInfo } = useOrderComplete();
    const displayCountryInfo = computed(
      () => packageInfo.value && getCountryInfoById(packageInfo.value.country)
    );
    const { payPackageIds, showPayment, startPay } = useOrderPay();
    onBeforeMount(async () => {
      await initPckInfo();
    });
    return {
      showDeclareTips,
      packageInfo,
      displayCountryInfo,
      payPackageIds,
      showPayment,
      startPay,
      closePayment: () => {
        showPayment.value = false;
      },
      paySuccess: async () => {
        showPayment.value = false;
        await initPckInfo();
        navOrderCompleteList({
          id: packageInfo.value!.id,
          type: OrderCompleteEnum.Pay,
        });
      },
      toDetail: (info) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { id, package_attr_num } = info;
        localStorage.setItem(
          `${USER_CLICK_PACKAGE_CACHE}:${package_attr_num}`,
          package_attr_num
        );
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < packageInfo.value!.packageAttr.length; i++) {
          const item = packageInfo.value!.packageAttr[i] as any;
          if (item.package_attr_num === package_attr_num) {
            item.is_clicked = true;
          }
        }
        navSubPkg(id);
      },
    };
  },
});
