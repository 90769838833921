
import { defineComponent, onBeforeMount } from 'vue';
import { useTeam } from '@/hooks/team';
export default defineComponent({
  setup() {
    const { teamList, initTeamList } = useTeam();
    onBeforeMount(() => {
      initTeamList();
    });
    return {
      teamList,
    };
  },
});
