import { getPkgRoute, getPkgInfo } from '@/api/package';
import { Ref, ref } from 'vue';
import { useUrlSearchParams } from '@vueuse/core';

export const usePackageInfo = () => {
  const { id } = useUrlSearchParams('history') as {id: string};
  const isShowMore = ref(false);
  const router: Ref<any> = ref([]);
  const packageInfo: Ref<any> = ref(null);

  const getRouter = async () => {
    const data = await getPkgRoute(id);
    router.value = data;
  };
  const showMoreTracking = () => {
    isShowMore.value = !isShowMore.value;
  };

  const getPackage = async () => {
    const data = await getPkgInfo(id);
    packageInfo.value = data;
  };
  return {
    isShowMore,
    router,
    packageInfo,
    getRouter,
    showMoreTracking,
    getPackage,

  };
};
