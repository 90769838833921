import { request } from '@/libs/request';
import { BannerItem } from '@/types/banner';

/** 获取首页banner配置 */
export const getBannerConfig = async (): Promise<BannerItem[]> => {
  const res = await request({
    url: `https://res-cn.public.gblinker.com/gblinker/hub_panda/data/banner.json?_=${Math.random()}`,
    method: 'GET',
  });
  return res;
};

export const initWetChatJSAPI = async (): Promise<any> => {
  return request({
    url: '/v1/wechat/js-sdk-config',
    method: 'POST',
    data: {
      url: window.location.href.split('#')[0],
    },
  });
};
