import { useProductStore } from '@/stores/module/products';
import { ProductForm } from '@/types/products';
import { useUrlSearchParams } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useDialog } from './dialog';
import { ToastTypeIconClass, useToast } from './toast';
const Schema = require('async-validator').default;

const categoryList = [
  { name: '服饰包箱', icon: 'btn_clothing', color: '#EB6750', id: '1' },
  { name: '图书文件', icon: 'btn_book', color: '#63A4EB', id: '2' },
  { name: '玩具木偶', icon: 'btn_toy', color: '#EBD06B', id: '3' },
  { name: '美妆洗护', icon: 'btn_makeup', color: '#F4779D', id: '4' },
  { name: '食品饮料', icon: 'btn_meat', color: '#EB6750', id: '5' },
  { name: '药品', icon: 'btn_medicine', color: '#63A4EB', id: '6' },
  { name: '家居家电', icon: 'btn_device', color: '#EBD06B', id: '7' },
  { name: '手机数码', icon: 'btn_game', color: '#F4779D', id: '8' },
  { name: '防疫物资', icon: 'btn_facialmask', color: '#EB6750', id: '9' },
  { name: '其他', icon: 'btn_classification', color: '#63A4EB', id: '10' },
];

const descriptor = {
  category_id: {
    type: 'string',
    required: true,
    validator: (rule: any, value: string, cb: Function) => {
      if (!value) {
        cb(new Error('请输入选择物品类目'));
      } else {
        cb();
      }
    },
  },
  name_cn: {
    type: 'string',
    required: true,
    validator: (rule: any, value: string, cb: Function) => {
      if (!value) {
        cb(new Error('请输入物品名称'));
      } else if (value.length > 50) {
        cb(`物品不能超过50个字符。当前为${value.length}`);
      } else {
        cb();
      }
    },
  },
  price: {
    type: 'number',
    required: true,
    validator: (rule: any, value: string, cb: Function) => {
      if (!value) {
        cb(new Error('请输入物品单价'));
      } else if (isNaN(Number(value))) {
        cb('物品单价请输入数字');
      } else {
        cb();
      }
    },
  },
  quantity: {
    type: 'number',
    required: true,
    validator: (rule: any, value: string, cb: Function) => {
      if (!value) {
        cb(new Error('请输入物品数量'));
      } else if (!/\d+/g.test(value)) {
        cb('请输入有效物品数量');
      } else {
        cb();
      }
    },
  },
};

const getDefaultForm = (): ProductForm => ({
  name_cn: '',
  price: undefined,
  quantity: undefined,
  category_id: 1,
});

// const defaultProductList = (): ProductForm[] => [];

export const useProductList = () => {
  const router = useRouter();
  const stores = useProductStore();
  const { products } = storeToRefs(stores);
  const { showDialog } = useDialog();
  const backToOrder = () => {
    let totalPrice = 0;
    for (const item of products.value) {
      const { quantity = 0, price = 0 } = item;
      totalPrice += quantity * price;
    }
    if (totalPrice < 15) {
      showDialog({ content: '物品申报总金额不能小于15元,请返回修改' });
      return;
    }
    localStorage.setItem('products', JSON.stringify(products.value));
    router.back();
  };
  const removeProduct = (index: number) => {
    showDialog({
      content: '确定要删除吗?',
      btns: [
        {
          text: '取消',
        },
        {
          text: '确定',
          fn: () => products.value.splice(index, 1),
        },
      ],
    });
  };
  const editProduct = (index: number) => {
    console.log(`index: ${index}`);
  };

  return {
    productsList: products,
    reset: stores.reset,
    backToOrder,
    editProduct,
    removeProduct,
  };
};

export const useProduct = () => {
  const { index } = useUrlSearchParams('history') as { index: number };
  const { showToast } = useToast();
  const form = ref(getDefaultForm());
  const stores = useProductStore();
  const { products: productsList } = storeToRefs(stores);
  // const { productsList } = useProductList();
  const router = useRouter();
  if (index > -1) {
    console.log(`index: ${index}`);
    const product = productsList.value[index];
    form.value = product;
  }
  const confirm = async () => {
    const validator = new Schema(descriptor);
    const isValidate = await validator
      .validate(form.value)
      .then(() => true)
      .catch(({ errors }: any): any => {
        for (const { message } of errors) {
          showToast(message, ToastTypeIconClass.warn);
          return false;
        }
      });
    if (!isValidate) return;
    if (index > -1) {
      productsList.value[index] = form.value;
      localStorage.setItem('products', JSON.stringify(productsList.value));
      showToast('修改成功');
      router.back();
      return;
    }
    productsList.value.push(form.value);
    localStorage.setItem('products', JSON.stringify(productsList.value));
    showToast('添加成功');
    router.back();
  };

  return {
    categoryList: ref(categoryList),
    confirm,
    form,
  };
};
