
import { defineComponent, onMounted, ref } from 'vue';
import Swiper, { Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';
Swiper.use([Pagination, Autoplay]);

export default defineComponent({
  name: 'GuideMore',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  setup: (prop, ctx) => {
    onMounted(() => {
      new Swiper('.swiper-guide-container', {
        direction: 'horizontal',
        loop: true,
        autoplay: {
          delay: 3000, // 1秒切换一次
          disableOnInteraction: false,
        },
        observer: true,
        pagination: {
          el: '.swiper-guide-pagination',
        },
      });
    });
    return {
      tutorialImages: ref([
        '//res-cn.public.gblinker.com/gblinker/wechat_mini/images/03143a6b6e9287c39b493da39f5f3a7a1.jpg',
        '//res-cn.public.gblinker.com/gblinker/wechat_mini/images/03143a6b6e9287c39b493da39f5f3a7a2.jpg',
        '//res-cn.public.gblinker.com/gblinker/wechat_mini/images/03143a6b6e9287c39b493da39f5f3a7a3.jpg',
        '//res-cn.public.gblinker.com/gblinker/wechat_mini/images/03143a6b6e9287c39b493da39f5f3a7a4.jpg',
      ]),
      closeTutorialModal: () => {
        ctx.emit('close');
      },
    };
  },
});
