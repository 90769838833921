import { getTeamMember } from '@/api/team';
import { ref, Ref } from 'vue';
import { useDialog } from './dialog';
import { commonErrHandle } from './errHandle';
import { to } from './to';

export const useTeam = () => {
  const teamList: Ref<any[]> = ref([]);
  const { showDialog } = useDialog();
  const initTeamList = async () => {
    const [err, res] = await to(getTeamMember());
    if (commonErrHandle(err)) {
      showDialog({ content: err as string });
    }
    teamList.value = res;
  };
  return {
    teamList,
    initTeamList,
  };
};
