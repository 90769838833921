
import { defineComponent, ref, onActivated } from 'vue';
import Icon from '@/components/common/icon.vue';
import { useStore } from '@/stores/module/userInfo';
import { storeToRefs } from 'pinia';
import { useClipboard } from '@vueuse/core';
import { useJumpPage } from '@/hooks/jumpPage';
import { useUser } from '@/hooks/user';
import { AddressListMode } from '@/types/address';

export default defineComponent({
  name: 'Mine',
  components: {
    Icon,
  },
  setup: () => {
    const store = useStore();
    const { userInfo, isLogin } = storeToRefs(store);
    const info = userInfo.value?.info || ({} as any);
    const { dialogVisiable, leaderCode, bindLeaderCode } = useUser();
    if (!info.avatarUrl) {
      Object.assign(info, {
        avatarUrl:
          'https://res-public-hk.public.gblinker.com/gblinker/images/img_avatar.png',
      });
    }
    if (!info?.nickname) {
      Object.assign(info, {
        nickname: '小格布',
      });
    }
    const {
      navTradeList,
      navAddressList,
      navRule,
      navWareHouseList,
      navMyTeam,
      navLogin,
      replaceCharge,
      navMyCoupon,
      navToShare,
    } = useJumpPage();
    const isShowBalance = ref(false);

    onActivated(() => {
      store.fetchUserInfo();
    });
    return {
      isLogin,
      userInfo,
      info: ref(info),
      isShowBalance,
      dialogVisiable,
      leaderCode,
      navWareHouseList,
      navLogin,
      replaceCharge,
      showBalance: () => {
        isShowBalance.value = !isShowBalance.value;
      },
      copyToClipboard: () => {
        const data = useClipboard({ source: userInfo.value!.userCode });
        console.log(data);
      },
      redirectToCharge: () => {
        location.href = decodeURIComponent(
          'https%3A%2F%2Fmp.weixin.qq.com%2Fs%2FLjqzCmAfPbg9yPGHJCBPUg'
        );
      },
      redirectToCash: () => {
        location.href = decodeURIComponent(
          'https%3A%2F%2Fmp.weixin.qq.com%2Fs%2FxseEgKydAHuEEt7WK8RP9g'
        );
      },
      redirectToTranList: () => {
        navTradeList();
      },
      redirectToAddressList: () => {
        navAddressList({
          mode: AddressListMode.Normal,
        });
      },
      showBindTeamQrcode: () => {
        if (userInfo.value?.parentId) {
          return;
        }
        leaderCode.value = '';
        dialogVisiable.value = true;
      },
      bindLeaderCode,
      navMyTeam,
      navMyCoupon,
      navToShare,
      redirectToService: () => {
        location.href = decodeURIComponent(
          'https%3A%2F%2Fmp.weixin.qq.com%2Fs%2FUP3O6-nhP62SBwnkDnJEKg'
        );
      },
      redirectToRule: () => {
        navRule();
      },
    };
  },
});
