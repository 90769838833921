
import { defineComponent, ref } from 'vue';
import Icon from '@/components/common/icon.vue';
import GuideMore from '@/components/guide-more.vue';
import { useJumpPage } from '@/hooks/jumpPage';

export default defineComponent({
  name: 'HomeMore',
  components: {
    Icon,
    GuideMore,
  },
  setup() {
    /** 是否展示教程 */
    const isShowGuideMore = ref(false);
    const { navExpressRate, navRule } = useJumpPage();
    return {
      isShowGuideMore,
      navRule,
      navExpressRate,
      showTutorialModal: () => {
        isShowGuideMore.value = true;
      },
      closeGuideMore: () => {
        isShowGuideMore.value = false;
      },
    };
  },
});
