
import { defineComponent, ref } from 'vue';
import Icon from '@/components/common/icon.vue';
import { useJumpPage } from '@/hooks/jumpPage';
import { useUser } from '@/hooks/user';
import { useProduct } from '@/hooks/product';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'ProductEdit',
  components: {
    Icon,
  },
  setup: () => {
    const router = useRouter();
    const { isLogin } = useUser();
    const { navLogin } = useJumpPage();
    const { categoryList, confirm, form } = useProduct();
    const selectedCategoryId = ref(1);
    return {
      selectedCategory: (item) => {
        selectedCategoryId.value = item.id;
        form.value.category_id = item.id;
      },
      selectedCategoryId,
      isLogin,
      navLogin,
      categoryList,
      confirm,
      form,
      router,
    };
  },
});
