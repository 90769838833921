import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79a7bd8f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "g-card order-form" }
const _hoisted_2 = { class: "form-header" }
const _hoisted_3 = { class: "form-subtitle" }
const _hoisted_4 = { class: "form-item" }
const _hoisted_5 = {
  key: 0,
  class: "form-item"
}
const _hoisted_6 = { class: "form-item" }
const _hoisted_7 = { class: "form-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_PackageInfoCountry = _resolveComponent("PackageInfoCountry")!
  const _component_PackageInfoTracking = _resolveComponent("PackageInfoTracking")!
  const _component_OrderProduct = _resolveComponent("OrderProduct")!
  const _component_PackageInfoValue = _resolveComponent("PackageInfoValue")!
  const _component_PackageInfoCargo = _resolveComponent("PackageInfoCargo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Icon, { icon: "icon_fillpackage" }),
        _createTextVNode(" 包裹信息 ")
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_PackageInfoCountry),
      _createVNode(_component_PackageInfoTracking)
    ]),
    (_ctx.showProduct)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_OrderProduct)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_PackageInfoValue)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_PackageInfoCargo)
    ])
  ]))
}