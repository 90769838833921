
import { defineComponent, onActivated } from 'vue';
import { storeToRefs } from 'pinia';
import { useStore } from '@/stores/module/userInfo';
import { useWareHouse } from '@/hooks/address';
import { useJumpPage } from '@/hooks/jumpPage';
import Icon from '@/components/common/icon.vue';
import BindMobileModel from '@/components/bind-mobile.vue';
import { userHandleBindMobileModle, useUser } from '@/hooks/user';
export default defineComponent({
  name: 'HomeTransition',
  components: {
    Icon,
    BindMobileModel,
  },
  setup() {
    const store = useStore();
    const { userInfo, hasMobile, hasUserInfo } = storeToRefs(store);
    const { warehouse, copyAddress, fetchWareHouseList } = useWareHouse();
    const { navLogin } = useJumpPage();
    const { isShowBindMobileModel, toggleModel } = userHandleBindMobileModle();
    const { isLogin } = useUser();
    onActivated(() => {
      if (isLogin.value) {
        fetchWareHouseList();
      }
    });
    return {
      isLogin,
      isShowBindMobileModel,
      hasUserInfo,
      hasMobile,
      warehouse,
      userInfo,
      copyAddress,
      toggleModel,
      navLogin,
      // navBindMobile,
    };
  },
});
