
import { defineComponent, onActivated } from 'vue';
import { useDefaultAddress } from '@/hooks/address';
import { useRouter } from 'vue-router';
import { useJumpPage } from '@/hooks/jumpPage';
import { AddressListMode } from '@/types/address';
import { useUser } from '@/hooks/user';
export default defineComponent({
  name: 'HomeAdderss',
  setup: () => {
    const { defaultAddress, hasAddress, defaultAddressDetail, fetchDefaultAddress } = useDefaultAddress();
    const { navAddressList, navLogin }  = useJumpPage();
    const router = useRouter();
    const { isLogin } = useUser();

    onActivated(() => {
      if (isLogin.value) {
        fetchDefaultAddress();
      }
    });
    return {
      isLogin,
      defaultAddress,
      hasAddress,
      defaultAddressDetail,
      navLogin,
      navigateTo: () => {
        if (!isLogin.value) {
          return navLogin();
        }
        if (hasAddress.value) {
          return navAddressList({
            mode: AddressListMode.Normal,
          });
        }
        router.push({
          name: 'AddressEdit',
        });
      },
    };
  },
});
