
import { defineComponent } from 'vue';
import { useCheckout } from '@/hooks/checkout';
export default defineComponent({
  name: 'OrderCheckout',
  components: {},
  setup: () => {
    const {
      userBalance,
      orderAmount,
      payment,
      selectPayment,
      paymentDialogForm,
      startPay,
      closePaymentDialog,
    } = useCheckout();

    return {
      userBalance,
      orderAmount,
      payment,
      selectPayment,
      paymentDialogForm,
      changePayment: (val) => {
        selectPayment.value = val.target.value;
      },
      closePaymentDialog,
      startPay,
    };
  },
});
