import { ChargeDto } from '@/types/charge';
import { to } from './to';
import { useLoading } from './loading';
import { useDialog } from './dialog';
import { chargeCallback, createChargeOrder } from '@/api/charge';
import { useJumpPage } from '@/hooks/jumpPage';
import { ref } from 'vue';
import Schema from 'async-validator';
import { chargeDescriptor } from '@/config/form-validator-descriptor';
// import { useToast } from './toast';

export const chargeFrom = {
  visible: false,
  amount: undefined as number | undefined,
  card_number: '',
  expiration_date: '',
  expiry: '',
  cvv: '',
  contact: {
    email: undefined,
    first_name: undefined,
    last_name: undefined,
    company: undefined,
    address: undefined,
    city: undefined,
    state: undefined,
    zipcode: undefined,
    country: undefined,
  },
};

const cardExpiryVal = (value) => {
  const ref = Array.from(value.split(/[\s/]+/, 2));
  let month = ref[0] as any;
  let year = ref[1] as any;
  // Allow for year shortcut
  if ((year !== null ? year.length : undefined) === 2 && /^\d+$/.test(year)) {
    let prefix = new Date().getFullYear() as any;
    prefix = prefix.toString().slice(0, 2);
    year = prefix + year;
  }
  month = parseInt(month, 10);
  year = parseInt(year, 10);
  return { month, year };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const validateCardExpiry = (month: any, year?: any) => {
  if (!month) {
    return false;
  }

  if (!year) {
    const assign = cardExpiryVal(month);
    month = assign.month;
    year = assign.year;
  }

  // Allow passing an object
  if (typeof month === 'object' && 'month' in month) {
    const assign$1 = month;
    month = assign$1.month;
    year = assign$1.year;
  }

  if (!month || !year) {
    return false;
  }

  month = month.toString().trim();
  year = year.toString().trim();

  if (!/^\d+$/.test(month)) {
    return false;
  }
  if (!/^\d+$/.test(year)) {
    return false;
  }
  if (!(1 <= month && month <= 12)) {
    return false;
  }

  if (year.length === 2) {
    if (year < 70) {
      year = `20${year}`;
    } else {
      year = `19${year}`;
    }
  }

  if (year.length !== 4) {
    return false;
  }

  const expiry = new Date(year, month);
  const currentTime = new Date();

  // Months start from 0 in JavaScript
  expiry.setMonth(expiry.getMonth() - 1);

  // The cc expires at the end of the month,
  // so we need to make the expiry the first day
  // of the month after
  expiry.setMonth(expiry.getMonth() + 1, 1);

  return expiry > currentTime;
};

const resetForm = (chargeFrom: any) => {
  const defaultChargeFrom = {
    visible: false,
    card_number: '',
    expiration_date: '',
    expiry: '',
    cvv: '',
    contact: {
      email: undefined,
      first_name: undefined,
      last_name: undefined,
      company: undefined,
      address: undefined,
      city: undefined,
      state: undefined,
      zipcode: undefined,
      country: undefined,
    },
  };
  Object.assign(chargeFrom.value, defaultChargeFrom);
};

export const useCharge = () => {
  /** 表单校验器 */
  const validator = new Schema(chargeDescriptor);

  const { showLoading, hideLoading } = useLoading();
  const { showDialog } = useDialog();
  const { navMine, replaceCharge } = useJumpPage();
  // const { showToast } = useToast();
  const charge = async (param: ChargeDto): Promise<any> => {
    showLoading();
    const [err, res] = await to(createChargeOrder(param));
    if (err) {
      showDialog({ content: err as string });
      return;
    }
    const payBy = 'convergepay';
    const { auth_token: authToken, verification, order_num: orderNum } = res;
    const callback = {
      onReady: () => {
        hideLoading();
        console.log('onReady');
      },
      onError: (error: any) => {
        showDialog({ content: error as string });
      },
      onCancelled: () => {
        showDialog({ content: '您取消了支付' as string });
      },
      onDeclined: async (response: any) => {
        const errorMsg =
          response.ssl_result_message || response.errorMessage || '未知错误';
        const postData = {
          ...response,
          verification,
          order_num: orderNum,
          pay_by: payBy,
          callback: 'DECLINED',
        };
        await to(chargeCallback(postData));
        showDialog({ content: `支付失败:${errorMsg}` });
      },
      onApproval: async (response: any) => {
        const postData = {
          ...response,
          verification,
          order_num: orderNum,
          pay_by: payBy,
          callback: 'APPROVAL',
        };
        await to(chargeCallback(postData));
        showDialog({
          content: '支付成功,请等待审核',
          btns: [
            {
              text: '返回上一页',
              fn: () => navMine(),
            },
            {
              text: '继续充值',
            },
          ],
        });
      },
    };
    const paymentFields = {
      ssl_txn_auth_token: authToken,
      verification,
      order_num: orderNum,
    };
    console.log(paymentFields, callback);
    // PayWithConverge.open(paymentFields, callback);
    return;
  };

  const chargeByCard = async (chargeFrom: any): Promise<any> => {
    // 校验表单
    const [validateErr] = await to(validator.validate(chargeFrom));
    if (validateErr) {
      return showDialog({
        // @ts-ignore
        content: validateErr.errors[0].message || '表单未填写完整，请检查',
      });
    }
    const validate = validateCardExpiry(chargeFrom.expiry);
    if (!validate) {
      return showDialog({
        content: '到期日期不正确',
      });
    }
    const { expiry: expirationDate } = chargeFrom;
    const ref = Array.from(expirationDate.split(/[\s/]+/, 2));
    const month = ref[0] as any;
    let year = ref[1] as any;
    if (year.length === 2) {
      year = new Date().getFullYear().toString().slice(0, 2) + year;
    }
    Object.assign(chargeFrom, {
      expiration_date: `${year}-${month}`,
    });
    showLoading();
    Object.assign(chargeFrom, { pay_by: 'yuansheng' });
    const [err, res] = await to(createChargeOrder(chargeFrom));
    hideLoading();
    if (err) {
      showDialog({ content: err as string });
      return false;
    }
    // eslint-disable-next-line no-param-reassign
    chargeFrom.visible = false;
    if (res) {
      showDialog({
        content: '支付成功,请等待审核',
        btns: [
          {
            text: '返回',
            fn: () => navMine(),
          },
          {
            text: '继续充值',
            fn: () => replaceCharge(),
          },
        ],
      });
    }
  };

  return {
    charge,
    chargeFrom: ref(chargeFrom),
    chargeByCard,
    validateCardExpiry,
    resetForm,
  };
};
