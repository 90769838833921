import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-626b0852"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PackageInfo = _resolveComponent("PackageInfo")!
  const _component_RecipientInfo = _resolveComponent("RecipientInfo")!
  const _component_OrderProtocol = _resolveComponent("OrderProtocol")!
  const _component_OrderRemark = _resolveComponent("OrderRemark")!
  const _component_OrderConfirm = _resolveComponent("OrderConfirm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PackageInfo),
      _createVNode(_component_RecipientInfo),
      _createVNode(_component_OrderProtocol),
      _createVNode(_component_OrderRemark),
      _createVNode(_component_OrderConfirm)
    ])
  ]))
}