
import { computed, defineComponent, onBeforeUnmount } from 'vue';
import { userBindMobile } from '@/hooks/user';
import Icon from '@/components/common/icon.vue';
import { useCountDown } from '@/hooks/countdown';
import { useToast } from '@/hooks/toast';
import { useDialog } from '@/hooks/dialog';
import { to } from '@/hooks/to';
import Picker from '@/components/common/picker.vue';
import { useLoading } from '@/hooks/loading';
import { commonErrHandle } from '@/hooks/errHandle';

export default defineComponent({
  components: {
    Icon,
    Picker,
  },
  emits: ['close', 'fail', 'success'],
  props: {
    isShowModel: {
      type: Boolean,
      default: false,
    },
  },
  setup: (prop, ctx) => {
    const { showLoading, hideLoading } = useLoading();
    const { showDialog } = useDialog();
    const { count, reset, start, isCounting } = useCountDown();
    const { showToast } = useToast();
    const {
      phoneNumber,
      selectCountryId,
      smsTypeText,
      verifyCode,
      isShowPicker,
      pickerOptions,
      fetchSendSmsCode,
      fetchMobileLogin,
      onClosePicker,
    } = userBindMobile();
    const btnText = computed(() => {
      if (isCounting.value) {
        return `${count.value}s`;
      }
      return '发送验证码';
    });
    onBeforeUnmount(() => {
      reset();
    });
    return {
      onClosePicker,
      isShowPicker,
      pickerOptions,
      btnText,
      phoneNumber,
      selectCountryId,
      verifyCode,
      smsTypeText,
      confirm: async () => {
        showLoading();
        const [err] = await to(fetchMobileLogin());
        console.log(err);
        hideLoading();
        if (commonErrHandle(err)) {
          return showDialog({
            content: err as string,
          });
        }
        showToast('登录成功');
        ctx.emit('close');
      },
      senMsgCode: async () => {
        if (isCounting.value) {
          return;
        }
        showLoading();
        const [err] = await to(fetchSendSmsCode());
        hideLoading();
        if (commonErrHandle(err)) {
          return showDialog({
            content: err as string,
          });
        }
        start();
        showToast('发送验证码成功');
      },
      closeModal: () => {
        ctx.emit('close');
      },
      showPicker: () => {
        isShowPicker.value = true;
      },
    };
  },
});
