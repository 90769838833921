import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e5380e5c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "container"
}
const _hoisted_2 = { class: "g-card" }
const _hoisted_3 = { class: "list" }
const _hoisted_4 = { class: "header" }
const _hoisted_5 = { class: "info" }
const _hoisted_6 = { class: "desc" }
const _hoisted_7 = { class: "amount" }
const _hoisted_8 = { class: "time" }
const _hoisted_9 = {
  key: 1,
  class: "empty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    class: "wrapper",
    onNextPage: _ctx.getTransactionList
  }, {
    default: _withCtx(() => [
      (_ctx.transactions.length> 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayTransactions, (transactionItem, key) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "month-list",
                    key: key
                  }, [
                    _createElementVNode("div", _hoisted_4, _toDisplayString(key), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(transactionItem, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "item",
                        key: item.id
                      }, [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("div", _hoisted_6, _toDisplayString(item.trading_type_label) + " - " + _toDisplayString(item.trading_code), 1),
                          _createElementVNode("div", _hoisted_7, " ¥" + _toDisplayString(item.amount), 1)
                        ]),
                        _createElementVNode("div", _hoisted_8, _toDisplayString(item.created), 1)
                      ]))
                    }), 128))
                  ]))
                }), 128))
              ])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_9, " 暂无交易 "))
    ]),
    _: 1
  }, 8, ["onNextPage"]))
}