
import { defineComponent, onActivated } from 'vue';
import Icon from '@/components/common/icon.vue';
import PageContainer from '@/components/common/page.vue';
import { useOrderList } from '@/hooks/order';
import { useJumpPage } from '@/hooks/jumpPage';
import Payment from '@/components/common/payment.vue';
import { useMediaQuery } from '@vueuse/core';
import { useUser } from '@/hooks/user';
export default defineComponent({
  name: 'OrderList',
  components: {
    Icon,
    PageContainer,
    Payment,
  },
  setup: () => {
    const isLargeScreen = useMediaQuery('(min-width: 750px)');
    const { navOrder, navOrderDetail, navLogin } = useJumpPage();
    const { isLogin } = useUser();
    const data = useOrderList();
    const {
      curNavKey,
      resetPage,
      getPageData,
      selectCurrentPage,
      selectCurrentUnpaid,
    } = data;

    const toggleTab = (key: string) => {
      if (curNavKey.value === key) return;
      curNavKey.value = key;
      resetPage();
      if (isLogin.value) {
        getPageData();
      }
    };
    onActivated(async () => {
      if (isLogin.value) {
        data.resetPage(true);
        await data.getPageData();
      }
    });
    return {
      // scrollHeight,
      isLargeScreen,
      isLogin,
      ...data,
      navOrder,
      navLogin,
      toggleTab,
      toDetail: (id) => {
        navOrderDetail(id);
      },
      selectCurrentPage,
      selectCurrentUnpaid,
    };
  },
});
