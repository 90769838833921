import { getExpressList, getExpressQuation } from '@/api/express';
import { ref, Ref, watch } from 'vue';
import { useOrder } from '@/hooks/order';
import { useDialog } from '@/hooks/dialog';
import { to } from './to';
import { useJumpPage } from './jumpPage';
import { useDebounceFn } from '@vueuse/core';
export const useExpress = () => {
  const expressList = ref([]);
  const { form, resetForm } = useOrder();
  const initExpressList = async () => {
    if (form.value.country) {
      expressList.value = await getExpressList(form.value.country);
    }
  };
  const onCountryChange = useDebounceFn((val, old) => {
    if (val !== old || expressList.value.length === 0) {
      initExpressList();
    }
  });
  watch(() => form.value.country, onCountryChange, {
    immediate: true,
    deep: true,
  });

  return {
    form,
    resetForm,
    expressList,
  };
};

export const useExpressRate = () => {
  const expressList = ref([]);
  const priceList: Ref<any> = ref(null);
  const { showDialog } = useDialog();
  const { navOrder } = useJumpPage();

  const reqForm = ref({
    sizeHeight: '',
    sizeLength: '',
    sizeWidth: '',
    weight: '',
    country: 'US',
    dimensionVisible: false,
  });
  const estimatePackage = async () => {
    const [err] = await to(validateForm());
    if (err) {
      return showDialog({
        content: err as string,
        btns: [{ text: '我知道了' }],
      });
    }
    priceList.value = await getExpressQuation(reqForm.value);
  };
  const initExpressList = async () => {
    expressList.value = await getExpressList(reqForm.value.country);
    // estimatePackage();
  };
  const toggleDimension = () => {
    reqForm.value.dimensionVisible = !reqForm.value.dimensionVisible;
  };
  const validateForm = async (): Promise<boolean> => {
    const {
      sizeHeight,
      sizeLength,
      sizeWidth,
      weight,
      country,
      dimensionVisible,
    } = reqForm.value;
    if (!country) {
      throw '请选择国家或地区';
    }
    if (!weight) {
      throw '请输入测算重量';
    }

    if (dimensionVisible && (!sizeHeight || !sizeLength || !sizeWidth)) {
      throw '请输入包裹尺寸';
    }

    return true;
  };
  watch(
    () => reqForm.value.country,
    (val, old) => {
      if (val !== old) {
        initExpressList();
        if (reqForm.value.weight) {
          estimatePackage();
        }
      }
    },
    {
      immediate: true,
      deep: true,
    }
  );

  // onBeforeMount(() => {
  //   initExpressList();
  // });

  const showExpressNote = (str) => {
    showDialog({
      content: str,
      btns: [
        {
          text: '知道了',
        },
      ],
    });
  };
  const gotoOrder = (info) => {
    navOrder({
      expressId: info.id,
      country: reqForm.value.country,
    });
  };
  return {
    reqForm,
    priceList,
    expressList,
    showExpressNote,
    toggleDimension,
    initExpressList,
    estimatePackage,
    gotoOrder,
  };
};
