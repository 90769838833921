import { initWetChatJSAPI } from '@/api';
import { beforePay, payOrder, queryPayOrder } from '@/api/payment';
import { isBytedanceWebview, isMobile, isWechat } from '@/libs/utils';
import router from '@/router';
import { useUrlSearchParams } from '@vueuse/core';
import { nextTick, onBeforeMount, Ref, ref } from 'vue';
import { useDialog } from './dialog';
import { commonErrHandle } from './errHandle';
import { useLoading } from './loading';
import { to } from './to';
import { ToastTypeIconClass, useToast } from './toast';
import QRCode from 'qrcode';

const paymentConfig = [
  {
    title: '格布钱包',
    note: '',
    id: 'balance',
    disabled: false,
    icon: 'https://res-cn.public.gblinker.com/gblinker/wechat_mini/images/789fc8489bf47b2aaa11675150919852.svg',
  },
  {
    title: '支付宝支付',
    note: '',
    id: 'alipay',
    disabled: isWechat() || isBytedanceWebview(),
    icon: 'https://res-cn.public.gblinker.com/gblinker/hub_panda/imgs/alipay_logo.png',
  },
  {
    title: '微信支付',
    id: 'wechat',
    note: '',
    disabled: isMobile(),
    icon: 'https://res-cn.public.gblinker.com/gblinker/wechat_mini/images/789fc8489bf47b2aaa11675150919854.svg',
  },
];

const wechatPay = async (orderId: number): Promise<boolean> => {
  const payType = isMobile() && isWechat() ? 'mp_wechat' : 'native_wechat';
  return await payOrder(payType, orderId);
};

const aliPay = async (orderId: number): Promise<boolean> => {
  const payType = isMobile() ? 'm_alipay' : 'pc_alipay';
  console.log(`payType: ${payType}`);
  return await payOrder(payType, orderId);
};

const balancePay = async (orderId: number): Promise<boolean> => {
  return await payOrder('balance', orderId);
};

const paySwitch = (type) => {
  let fn;
  switch (type) {
    case 'wechat':
      fn = wechatPay;
      break;
    case 'balance':
      fn = balancePay;
      break;
    case 'alipay':
      fn = aliPay;
      break;
    default:
      alert('您未选择支付方式');
      break;
  }
  return fn;
};

const queryOrder = async (outTradeNo: string) =>
  await queryPayOrder(outTradeNo);

const paymentDialogForm = ref({
  visible: false,
  title: '',
  qrcode: '',
});

const timer: any = ref(0);

export const useCheckout = () => {
  const payment = ref(paymentConfig);
  const selectPayment = ref('balance');
  const payOrderId: Ref<Number> = ref(0);

  const userBalance = ref(0);
  const orderAmount = ref(0);
  const { showToast } = useToast();
  const { showDialog } = useDialog();
  const { showLoading, hideLoading } = useLoading();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const prePay = async (): Promise<any> => {
    showLoading();
    const [err, res] = await to(beforePay(payOrderId.value));
    hideLoading();
    if (err) {
      showDialog({ content: err as string });
      throw err;
    }
    userBalance.value = res.user_balance;
    orderAmount.value = res.pay_order_amount;
    const formatBalance = userBalance.value.toFixed(2).toLocaleString();

    payment.value = paymentConfig.map((item) => {
      let note = '';
      if (item.id === 'balance') {
        note = `（剩余¥${formatBalance}）`;
      }
      return { ...item, note };
    });
  };

  onBeforeMount(async () => {
    console.log('onBeforeMount');
    const { id } = useUrlSearchParams('history') as { id: string };
    if (!id) {
      router.replace({
        name: 'OrderList',
      });
      return;
    }
    payOrderId.value = Number(id);
    if (isWechat()) {
      const res = await initWetChatJSAPI();
      const { appId, nonceStr, signature, timestamp } = res;
      (window as any).wx.config({
        debug: false,
        appId,
        timestamp,
        nonceStr,
        signature,
        jsApiList: ['chooseWXPay'], // 必填，需要使用的JS接口列表
      });
      (window as any).wx.ready(() => {
        payment.value.map((item) => {
          if (item.id === 'wechat') {
            item.disabled = false;
          }
        });
      });
    }
    prePay();
  });
  const startPay = async () => {
    console.log(selectPayment.value);
    showLoading('加载中');
    const fn = paySwitch(selectPayment.value);
    const [err, res] = await to(fn(payOrderId.value));
    hideLoading();
    if (res === 'success') {
      showToast('支付成功');
      setTimeout(() => {
        router.replace({
          name: 'OrderList',
        });
      }, 500);
      return;
    }
    console.log(err);
    if (commonErrHandle(err)) {
      showDialog({
        content: err as string,
      });
      throw '支付失败';
    }
    if ('wechat' === selectPayment.value) {
      if (isWechat()) {
        const { nonceStr, paySign, timestamp, signType } = res as any;
        (window as any).wx.ready(function () {
          hideLoading();
          (window as any).wx.chooseWXPay({
            timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            nonceStr,
            package: (res as any).package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
            paySign, // 支付签名
            success(res) {
              console.log(res);
              // res.errMsg === 'chooseWXPay:ok'方式判断前端返回,微信团队郑重提示：
              // res.errMsg将在用户支付成功后返回ok，但并不保证它绝对可靠， 切记。
              if (res.errMsg === 'chooseWXPay:ok') {
                showToast('支付成功');
                setTimeout(() => {
                  router.replace({
                    name: 'OrderList',
                  });
                }, 500);
              }
            },
            cancel() {
              showDialog({
                content: '您取消了支付',
              });
            },
            fail(e) {
              console.log(e);
              showDialog({
                content: '支付失败',
              });
            },
          });
        });
        return;
      }
      const { code_url, out_trade_no } = res as any;
      timer.value = setInterval(() => {
        queryOrder(out_trade_no).then((res) => {
          const { tradeStatus } = res;
          if (tradeStatus === 1) {
            hideLoading();
            clearInterval(timer.value);
            paymentDialogForm.value.visible = false;
            paymentDialogForm.value.qrcode = '';
            showToast('支付成功');
            setTimeout(() => {
              router.replace({
                name: 'OrderList',
              });
            }, 500);
            return;
          }
        });
      }, 1000);
      hideLoading();
      paymentDialogForm.value.visible = true;
      paymentDialogForm.value.title = '微信';
      nextTick(() => {
        QRCode.toDataURL(code_url).then(
          (url) => (paymentDialogForm.value.qrcode = url)
        );
      });
      return;
    }
    if ('alipay' === selectPayment.value) {
      showLoading('支付中');
      const { data, out_trade_no } = res as any;
      if (isMobile()) {
        location.href = data;
        return;
      }
      window.open(data, '_blank');
      timer.value = setInterval(() => {
        queryOrder(out_trade_no).then((res) => {
          const { tradeStatus } = res;
          if (tradeStatus === 1) {
            hideLoading();
            clearInterval(timer.value);
            setTimeout(() => {
              router.replace({
                name: 'OrderList',
              });
            }, 500);
          }
        });
      }, 1000);
      setTimeout(() => {
        hideLoading();
        clearInterval(timer.value);
        showToast('支付超时', 'warn' as ToastTypeIconClass);
        setTimeout(() => {
          router.replace({
            name: 'OrderList',
          });
        }, 500);
      }, 1000 * 60 * 15);
      return;
    }
  };
  const closePaymentDialog = () => {
    showToast('您取消了支付', 'warn' as ToastTypeIconClass);
    paymentDialogForm.value.visible = false;
    clearInterval(timer.value);
  };
  return {
    userBalance,
    orderAmount,
    payment,
    selectPayment,
    queryOrder,
    paySwitch,
    paymentDialogForm,
    startPay,
    closePaymentDialog,
  };
};
