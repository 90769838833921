
import { computed, defineComponent, onBeforeMount, ref } from 'vue';
import Icon from '@/components/common/icon.vue';
import { useOrderComplete } from '@/hooks/order';
import { useWareHouse } from '@/hooks/address';
import { useCountry } from '@/hooks/country';
import { useLoading } from '@/hooks/loading';
import { useDialog } from '@/hooks/dialog';
import { to } from '@/hooks/to';
import { commonErrHandle } from '@/hooks/errHandle';
export default defineComponent({
  name: 'OrderComplete',
  components: {
    Icon,
  },
  setup: () => {
    const { showLoading, hideLoading } = useLoading();
    const { showDialog } = useDialog();
    const { packageInfo, initPckInfo, type, updateLogisticsCode, logisticsCode } = useOrderComplete();
    const { warehouse, copyAddress, fetchWareHouseList } = useWareHouse();
    const displayWareHouse = computed(() => warehouse.value[0]);
    const dialogVisiable = ref(false);
    /** 国家相关 */
    const { getCountryInfoById } = useCountry();
    /** 展示的国家信息 */
    const displayCountryInfo = computed(() => {
      if (packageInfo.value) {
        return getCountryInfoById(packageInfo.value.country);
      }
      return null;
    });
    onBeforeMount(async () => {
      showLoading();
      await initPckInfo();
      await fetchWareHouseList();
      hideLoading();
    });
    return {
      type,
      packageInfo,
      displayCountryInfo,
      displayWareHouse,
      logisticsCode,
      dialogVisiable,
      updateCode: async () => {
        showLoading();
        const [err] = await to(updateLogisticsCode());
        if (commonErrHandle(err)) {
          hideLoading();
          return showDialog({ content: err as string });
        }
        dialogVisiable.value = false;
        await initPckInfo();
        hideLoading();
      },
      openLogisticsModal: () => {
        dialogVisiable.value = true;
      },
      closeLogisticsModal: () => {
        dialogVisiable.value = false;
      },
      initPckInfo,
      copyAddress,
    };
  },
});
