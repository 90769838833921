
import { defineComponent, onUnmounted } from 'vue';
import Icon from '@/components/common/icon.vue';
import { useJumpPage } from '@/hooks/jumpPage';
import { useProductList } from '@/hooks/product';
let isFromOrderPage = false;
export default defineComponent({
  name: 'ProductList',
  components: {
    Icon,
  },
  beforeRouteEnter: (to, from) => {
    isFromOrderPage = from.name === 'Order';
  },
  setup: () => {
    onUnmounted(() => {
      console.log('onUnmounted');
      localStorage.setItem('products', JSON.stringify(productsList.value));
    });
    const { navProductEdit } = useJumpPage();
    const { productsList, backToOrder, reset, removeProduct } =
      useProductList();
    if (isFromOrderPage) {
      const resetForm = !localStorage.getItem('products');
      if (resetForm) reset();
    }
    return {
      navProductEdit,
      productsList,
      backToOrder,
      removeProduct,
      editProduct: (index: string) => {
        navProductEdit(index);
      },
    };
  },
});
