
import { defineComponent, nextTick, onBeforeMount, PropType, ref } from 'vue';
import Icon from '@/components/common/icon.vue';
import { usePayment } from '@/hooks/payment';
import { to } from '@/hooks/to';
import { useDialog } from '@/hooks/dialog';
import { useLoading } from '@/hooks/loading';
import { commonErrHandle } from '@/hooks/errHandle';
import { isMobile, isWechat } from '@/libs/utils';
import QRCode from 'qrcode';
import { initWetChatJSAPI } from '@/api';

export default defineComponent({
  name: 'Payment',
  components: {
    Icon,
  },
  props: {
    packageIds: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  emits: ['close', 'fail', 'success'],
  setup: (prop, ctx) => {
    const {
      payment,
      selectPayment,
      paySwitch,
      changePayment,
      hasCoupon,
      paymentDialogForm,
      selectCoupon,
      initPaymentInfo,
      coupons,
      queryOrder,
    } = usePayment();
    const { showLoading, hideLoading } = useLoading();
    const { showDialog } = useDialog();
    const showCoupons = ref(false);
    onBeforeMount(async () => {
      initPaymentInfo(prop.packageIds);
      if (isWechat()) {
        const res = await initWetChatJSAPI();
        const { appId, nonceStr, signature, timestamp } = res;
        (window as any).wx.config({
          debug: false,
          appId,
          timestamp,
          nonceStr,
          signature,
          jsApiList: ['chooseWXPay'], // 必填，需要使用的JS接口列表
        });
        (window as any).wx.ready(() => {
          payment.value.map((item) => {
            if (item.id === 'wechat') {
              item.disabled = false;
            }
          });
        });
      }
    });
    const timer: any = ref(0);
    return {
      payment,
      balance: '*',
      selectPayment,
      hasCoupon,
      selectCoupon,
      coupons,
      showCoupons,
      paymentDialogForm,
      changePayment,
      closeModal: () => {
        ctx.emit('close', {
          msg: '您取消了付款',
          package_ids: prop.packageIds,
        });
        ctx.emit('fail', {
          msg: '您取消了付款',
        });
      },
      hiddenCoupon: () => {
        showCoupons.value = false;
      },
      openCoupon: () => {
        showCoupons.value = true;
      },
      changeCoupon: (item: any) => {
        selectCoupon.value.id = item.id;
        selectCoupon.value.coupon = item.coupon;
        selectCoupon.value.coupon_type = item.coupon_type;
        selectCoupon.value.coupon_amount = item.coupon_amount;
        showCoupons.value = false;
      },
      closePaymentDialog: () => {
        paymentDialogForm.value.visible = false;
        clearInterval(timer.value);
      },
      startPay: async () => {
        showLoading();
        const userCouponIds = selectCoupon.value.id
          ? [selectCoupon.value.id]
          : [];
        const fn = paySwitch(selectPayment.value);
        const [err, res] = await to(fn(prop.packageIds, userCouponIds));
        if (res === 'success') {
          ctx.emit('success', res);
          return;
        }
        if (commonErrHandle(err)) {
          showDialog({
            content: err as string,
          });
          throw '支付失败';
        }
        if ('alipay' === selectPayment.value) {
          const { data, out_trade_no } = res as any;
          if (isMobile()) {
            location.href = data;
            return;
          }
          window.open(data, '_blank');
          timer.value = setInterval(() => {
            queryOrder(out_trade_no).then((res) => {
              const { tradeStatus } = res;
              if (tradeStatus === 1) {
                hideLoading();
                clearInterval(timer.value);
                ctx.emit('success', res);
              }
            });
          }, 1000);
          setTimeout(() => {
            hideLoading();
            clearInterval(timer.value);
            ctx.emit('fail', {
              msg: '支付超时',
            });
          }, 1000 * 60 * 15);
          return;
        }
        if ('wechat' === selectPayment.value) {
          if (isMobile()) {
            const { nonceStr, paySign, timestamp, signType } = res as any;
            (window as any).wx.ready(function () {
              hideLoading();
              (window as any).wx.chooseWXPay({
                timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                nonceStr,
                package: (res as any).package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
                paySign, // 支付签名
                success(res) {
                  // res.errMsg === 'chooseWXPay:ok'方式判断前端返回,微信团队郑重提示：
                  // res.errMsg将在用户支付成功后返回ok，但并不保证它绝对可靠， 切记。
                  alert(res);
                  if (res.errMsg === 'chooseWXPay:ok') {
                    alert('支付成功');
                    ctx.emit('success', res);
                  }
                },
                cancel() {
                  ctx.emit('fail', {
                    msg: '您取消了支付',
                  });
                },
                fail() {
                  ctx.emit('fail', {
                    msg: '支付失败',
                  });
                },
              });
            });
            return;
          }
          const { code_url, out_trade_no } = res as any;
          timer.value = setInterval(() => {
            queryOrder(out_trade_no).then((res) => {
              const { tradeStatus } = res;
              if (tradeStatus === 1) {
                hideLoading();
                clearInterval(timer.value);
                ctx.emit('success', res);
              }
            });
          }, 1000);
          hideLoading();
          if (isMobile()) {
            window.open(code_url);
            return;
          }
          paymentDialogForm.value.visible = true;
          paymentDialogForm.value.title = '微信';
          nextTick(() => {
            QRCode.toDataURL(code_url).then(
              (url) => (paymentDialogForm.value.qrcode = url)
            );
          });
          return;
        }
        ctx.emit('success', res);
      },
    };
  },
});
