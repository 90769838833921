
import { defineComponent } from 'vue';
import { useOrder } from '@/hooks/order';
import { useJumpPage } from '@/hooks/jumpPage';
export default defineComponent({
  name: 'OrderProtocol',
  components: {
  },
  setup: () => {
    const { form } = useOrder();
    const { navRule } = useJumpPage();
    return {
      form,
      navRule,
    };
  },
});
