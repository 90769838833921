
import { defineComponent, ref } from 'vue';
import Icon from '@/components/common/icon.vue';
import { useJumpPage } from '@/hooks/jumpPage';
import { useMediaQuery } from '@vueuse/core';
import { useUser } from '@/hooks/user';
import { useOrderComfirm } from '@/hooks/order';
export default defineComponent({
  name: 'OrderList',
  components: {
    Icon,
  },
  setup: () => {
    const isLargeScreen = useMediaQuery('(min-width: 750px)');
    const { navSubPkg, navLogin } = useJumpPage();
    const { isLogin } = useUser();
    const {
      selectCouponId,
      couponsList,
      paymentInfo,
      packages,
      selectdCoupon,
      hasCoupon,
      getPackages,
      gotoPay,
    } = useOrderComfirm();
    const visible = ref(false);
    return {
      isLargeScreen,
      isLogin,
      navLogin,
      toDetail: (id) => {
        navSubPkg(id);
      },
      selectCouponId,
      couponsList,
      paymentInfo,
      packages,
      selectdCoupon,
      openCoupon: () => {
        visible.value = true;
      },
      selectCoupon: async (e) => {
        const coupon = JSON.parse(e.target.value);
        selectdCoupon.value.checked = true;
        selectdCoupon.value.id = coupon.id;
        selectdCoupon.value.coupon_amount = coupon.coupon_amount;
        selectdCoupon.value.coupon_type = coupon.coupon_type;
        selectCouponId.value = coupon.id;
        visible.value = false;
        await getPackages();
      },
      hiddenCoupon: () => {
        visible.value = false;
      },
      visible,
      hasCoupon,
      gotoPay,
    };
  },
});
