import { getBannerConfig } from '@/api';
import { BannerItem } from '@/types/banner';
import { onBeforeMount, onMounted, Ref, ref } from 'vue';
import { useMounted } from '@vueuse/core';
import { to } from '@/hooks/to';
import Swiper, { Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';

Swiper.use([Pagination, Autoplay]);

/** 获取首页banner配置数据 */
export const fetchBannerConfig = () => getBannerConfig();

/** 初始化swiper */
export const initSwiper = () => new Swiper('.swiper-container', {
  direction: 'horizontal',
  loop: true,
  autoplay: {
    delay: 3000, // 1秒切换一次
    disableOnInteraction: false,
  },
  observer: true,
  pagination: {
    el: '.swiper-pagination',
  },
});

/** useBanner */
export const useBanner = () => {
  const bannerList: Ref<BannerItem[]> = ref([]);
  const isMounted = useMounted();
  let swiperInstance: Swiper| null = null ;

  onBeforeMount(async () => {
    const [err, res] = await to(getBannerConfig());
    if (err) {
      throw '获取banner图失败';
    }
    bannerList.value = res;
    if (isMounted.value && !swiperInstance) {
      swiperInstance = initSwiper();
    }
  });
  onMounted(async () => {
    if (swiperInstance || bannerList.value.length === 0) {
      return;
    }
    swiperInstance = initSwiper();
  });
  return {
    bannerList,
  };
};
