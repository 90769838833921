import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7662cf46"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "item" }
const _hoisted_4 = { class: "name" }
const _hoisted_5 = { class: "item" }
const _hoisted_6 = { class: "text" }
const _hoisted_7 = { class: "item" }
const _hoisted_8 = { class: "text" }
const _hoisted_9 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.warehouse, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "g-card warehouse-address",
          key: item.id
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Icon, {
              class: "icon",
              icon: "icon_user"
            }),
            _createElementVNode("span", _hoisted_4, _toDisplayString(item.name?item.name:item.title), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Icon, {
              class: "icon",
              icon: "icon_phone"
            }),
            _createElementVNode("span", _hoisted_6, _toDisplayString(item.phone), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_Icon, {
              class: "icon",
              icon: "icon_address"
            }),
            _createElementVNode("span", _hoisted_8, _toDisplayString(item.state) + _toDisplayString(item.city) + _toDisplayString(item.address1) + _toDisplayString(item.address2||''), 1)
          ]),
          _createElementVNode("div", {
            onClick: ($event: any) => (_ctx.copyAddress(item)),
            class: "copy-to-paste"
          }, null, 8, _hoisted_9)
        ]))
      }), 128))
    ])
  ]))
}