
import { defineComponent, onMounted, watch } from 'vue';
import Swiper, { Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';
Swiper.use([Pagination, Autoplay]);

export default defineComponent({
  name: 'Gallery',
  props: {
    isShowDel: {
      type: Boolean,
      default: false,
    },
    imgs: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    curImgIndex: {
      type: Number,
      default: 0,
    },
  },
  emits: ['update:visible'],
  setup: (prop, ctx) => {
    let swiperInstance: Swiper | null = null;
    watch(() => prop.curImgIndex, (val) => {
      swiperInstance!.slideTo(val);
    });
    onMounted(() => {
      swiperInstance = new Swiper('.gallery-container', {
        direction: 'horizontal',
        loop: false,
        observer: true,
        initialSlide: prop.curImgIndex,
        autoplay: false,
        pagination: {
          el: '.gallery-pagination',
        },
      });
    });

    return {
      toggleShow: () => {
        ctx.emit('update:visible');
      },
    };
  },
});
